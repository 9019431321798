import { PedagogicalDiaryService } from "@/core/services/apis.service";

const RESOURCE = "email";

export const composeNewEmail = (payload) => {
    return PedagogicalDiaryService.post(`${RESOURCE}/send`, payload);
};

export default {
    composeNewEmail
}