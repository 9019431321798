f
<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Registro de visita para
            <span
              :class="`font-weight-bold text-h5 ${
                isStudent
                  ? 'blue--text text--darken-3'
                  : 'orange--text text--darken-1'
              }`"
              >estudiante</span
            >

            <span class="d-block text-muted pt-2 font-size-sm"
              >Puede registrar asistencia, el detalle de visita y medicamentos
              suministrados.
            </span>
          </h3>
        </div>
        <v-btn color="primary" class="mt-5" @click="returnTo('dashboard')">
          Regresar al dashboard
        </v-btn>
      </div>
      <div class="card-body">
        <!-- Begin:: Student autocomplete -->
        <span v-if="Object.entries(selectedStudent).length < 1">
          <v-row>
            <!-- begin::search text field -->
            <v-col class="" cols="12">
              <v-text-field
                filled
                rounded
                prepend-inner-icon="mdi-magnify"
                placeholder="Buscar estudiante"
                v-model="students.search"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-n8">
            <!-- begin::search text field -->
            <v-col class="" cols="12">
              <p v-if="formattedStudents.length > 0">
                {{
                  formattedStudents.length < 2
                    ? "Se encontró una coincidencia: "
                    : `Mostrando las primeras ${formattedStudents.length} coincidencias: `
                }}
              </p>
              <v-data-table
                :class="`${
                  students.isLoading ? 'disable-pointer-events' : ''
                } cursor`"
                v-if="formattedStudents.length > 0 && students.search !== ''"
                :headers="students.headers"
                :items="formattedStudents"
                :loading="students.isLoading"
                :disabled="students.isLoading"
                :mobile-breakpoint="800"
                no-results-text=""
                hide-default-footer
                @click:row="getStudentInformationUpdated"
              >
                <!-- Student code -->
                <!-- Student information -->
                <template v-slot:[`item.full_name`]="{ item }">
                  <div class="py-2">
                    <div class="d-flex">
                      <!-- Student photo -->
                      <div>
                        <v-avatar color="primary lighten-5" size="70">
                          <!-- <img :src="item.photo" :alt="item.full_name" /> -->
                          <v-icon large color="primary"
                            >mdi-account-outline</v-icon
                          >
                        </v-avatar>
                      </div>
                      <!-- Student Name and Code -->
                      <div class="ml-3 d-flex flex-column justify-center">
                        <p class="mb-0 text-h6 font-weight-bold">
                          {{ item.code }}
                        </p>
                        <p class="mb-0 text-body-1 font-weight-medium">
                          {{ item.full_name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
                <!-- Student name -->
              </v-data-table>
              <div
                class="pa-3"
                v-if="students.search !== '' && formattedStudents.length == 0"
              >
                <p class="text-body-1 mb-0">
                  No se encontraron estudiantes que tengan el nombre o código
                  <span class="font-weight-bold">"{{ students.search }}"</span>.
                </p>
              </div>
            </v-col>
          </v-row>
        </span>
        <!-- End:: Student autocomplete -->

        <v-row v-if="hasSelectedStudent">
          <v-col
            v-if="
              isLoadingIndividualStudentInformation ||
              isLoadingStudentGuideTeacher ||
              isLoadingActiveSchedule
            "
            cols="12"
            md="8"
            class="d-flex justify-center align-center flex-column py-15"
          >
            <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <p class="mt-5">Generando formulario...</p>
          </v-col>

          <v-col v-else cols="12" md="8">
            <v-timeline dense align-top>
              <v-divider
                v-if="Object.entries(selectedStudent).length > 0 && isStudent"
                class="mt-n6"
              ></v-divider>
              <v-timeline-item color="primary">
                <v-row>
                  <v-col cols="12">
                    <p class="text-h5 mb-4 mt-2 font-weight-bold">
                      Registro de signos vitales
                    </p>
                  </v-col>
                </v-row>
                <v-row class="mb-8">
                  <v-col cols="12" md="9" class="py-0 d-flex align-center">
                    <form-group
                      :validator="$v.selectedVitalSign"
                      name="signo vital"
                    >
                      <v-select
                        slot-scope="{ attrs }"
                        v-bind="attrs"
                        label="Signos vitales"
                        :items="vitalSigns.data"
                        item-value="id"
                        item-text="name"
                        v-model="selectedVitalSign"
                        hide-details
                        filled
                        rounded
                        return-object
                        @click="clearSelectedVitalSign()"
                        @change="openVitalSignsRegistrationModal()"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" small v-if="index === 0">
                            <span>{{ item.name }}</span>
                          </v-chip>
                        </template>
                      </v-select>
                    </form-group>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="clinicVisit.vital_sign_id.length > 0"
                          v-bind="attrs"
                          v-on="on"
                          @click="showMdlVitalSigns"
                          icon
                          color="amber"
                          class="ml-3"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar signos vitales</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row v-if="clinicVisit.vital_sign_id.length > 0">
                  <v-col cols="12">
                    <v-card outlined class="rounded-lg">
                      <p class="text-h6 font-weight-bold pt-4 mb-0 pl-5">
                        Signos vitales registrados:
                      </p>
                      <v-list two-line>
                        <v-scroll-y-transition
                          :hide-on-leave="true"
                          :leave-absolute="true"
                          group
                        >
                          <v-list-item
                            v-for="(
                              recordedVitalSign, index
                            ) in clinicVisit.vital_sign_id"
                            :key="index"
                          >
                            <v-list-item-avatar color="primary lighten-5">
                              <v-icon color="primary" dark> mdi-pulse </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                >{{ recordedVitalSign.measurement }}
                                {{ recordedVitalSign.unit }}</v-list-item-title
                              >
                              <v-list-item-subtitle>{{
                                recordedVitalSign.name
                              }}</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                              <v-tooltip color="red" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    color="red"
                                    @click="
                                      removeVitalSignFormList(
                                        recordedVitalSign.id
                                      )
                                    "
                                  >
                                    <v-icon color="red">mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                                <span>Remover de la lista</span>
                              </v-tooltip>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-scroll-y-transition>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item color="primary">
                <v-row>
                  <v-col cols="12">
                    <p class="text-h5 mb-4 mt-2 font-weight-bold">
                      Detalle de visita
                    </p>
                  </v-col>
                </v-row>
                <v-row class="mb-8">
                  <v-col cols="12" md="10" class="py-0 d-flex align-center">
                    <form-group
                      :validator="$v.clinicVisit.disease_id"
                      name="padecimiento"
                    >
                      <v-autocomplete
                        slot-scope="{ attrs }"
                        v-bind="attrs"
                        label="Padecimiento"
                        :items="diseases.data"
                        item-value="id"
                        item-text="name"
                        v-model="selectedIllness"
                        hide-details
                        clearable
                        filled
                        rounded
                        return-object
                        @change="saveDiseasesRecord(selectedIllness)"
                        @click:clear="resetSelectedIllnes()"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" small v-if="index === 0">
                            <span>{{ item.name }}</span>
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </form-group>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="clinicVisit.disease_id.length > 0"
                          v-bind="attrs"
                          v-on="on"
                          @click="showMdlDiseases"
                          icon
                          color="amber"
                          class="ml-3"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar lista de padecimientos</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <form-group
                      name="detalle de visita"
                      :validator="$v.clinicVisit.diagnostic"
                    >
                      <v-textarea
                        slot-scope="{ attrs }"
                        v-bind="attrs"
                        counter
                        filled
                        clearable
                        rounded
                        auto-grow
                        rows="2"
                        label="Detalle de visita"
                        v-model.trim="clinicVisit.diagnostic"
                        @blur="$v.clinicVisit.diagnostic.$touch()"
                        @paste="handlePaste($event, 'diagnostic')"
                      ></v-textarea>
                    </form-group>
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item color="primary">
                <v-row>
                  <v-col cols="12">
                    <p class="text-h5 mb-4 mt-2 font-weight-bold">
                      Registro de medicamentos suministrados
                    </p>
                  </v-col>
                </v-row>
                <v-row class="mb-8">
                  <v-col cols="12" md="9" class="py-0 d-flex align-center">
                    <form-group
                      :validator="$v.clinicVisit.disease_id"
                      name="medicamento"
                    >
                      <v-autocomplete
                        slot-scope="{ attrs }"
                        v-bind="attrs"
                        label="Medicamento"
                        :items="medicines.data"
                        item-value="id"
                        item-text="name"
                        v-model="selectedMedicine"
                        hide-details
                        filled
                        rounded
                        return-object
                        @click="clearSelectedMedicine()"
                        @change="openDosisRegistrationModal()"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" small v-if="index === 0">
                            <span>{{ item.name }}</span>
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </form-group>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="clinicVisit.medication_detail.length > 0"
                          v-bind="attrs"
                          v-on="on"
                          icon
                          @click="showMdlMedicines"
                          color="amber"
                          class="ml-3"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar lista de medicamentos</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row v-if="clinicVisit.medication_detail.length > 0">
                  <v-col cols="12">
                    <v-card outlined class="rounded-lg">
                      <p class="text-h6 font-weight-bold pt-4 mb-0 pl-5">
                        Medicamentos suministrados registrados:
                      </p>
                      <v-list two-line>
                        <v-scroll-y-transition
                          :hide-on-leave="true"
                          :leave-absolute="true"
                          group
                        >
                          <v-list-item
                            v-for="(
                              givenMedicine, index
                            ) in clinicVisit.medication_detail"
                            :key="index"
                          >
                            <v-list-item-avatar color="primary lighten-5">
                              <v-icon color="primary" dark> mdi-pill </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title>{{
                                givenMedicine.name
                              }}</v-list-item-title>
                              <v-list-item-subtitle>{{
                                givenMedicine.dosis_supplied
                              }}</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                              <v-tooltip color="red" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    color="red"
                                    @click="
                                      removeGivenMedicineFormList(
                                        givenMedicine.medicine_id
                                      )
                                    "
                                  >
                                    <v-icon color="red">mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                                <span>Remover de la lista</span>
                              </v-tooltip>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-scroll-y-transition>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item
                v-if="
                  Object.entries(selectedStudentActiveSchedule).length > 0 ||
                  Object.entries(selectedStudentGuideTeacher).length > 0
                "
                color="primary"
              >
                <v-row>
                  <v-col cols="12">
                    <p class="text-h5 mb-4 mt-2 font-weight-bold">
                      Registro de visita
                    </p>
                  </v-col>
                </v-row>
                <v-row class="mb-8">
                  <v-col cols="12" class="py-0">
                    <v-card outlined class="rounded-lg">
                      <p class="text-h6 font-weight-bold pt-4 mb-0 pl-5">
                        Acciones
                      </p>
                      <v-list two-line class="pb-0">
                        <v-list-item-group
                          v-model="selectedItem"
                          color="primary"
                        >
                          <!-- --------------------------- -->
                          <v-list-item
                            v-if="
                              Object.entries(selectedStudentGuideTeacher)
                                .length > 0
                            "
                          >
                            <v-list-item-avatar color="primary lighten-5">
                              <v-icon color="primary" dark>
                                mdi-account-badge-outline
                              </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                >Notificar docente guía</v-list-item-title
                              >
                            </v-list-item-content>

                            <v-list-item-icon>
                              <v-checkbox
                                class="mb-n5"
                                v-model="notifyGuideTeacher"
                              ></v-checkbox>
                            </v-list-item-icon>
                          </v-list-item>
                          <!-- --------------------------- -->
                          <v-list-item
                            v-if="
                              Object.entries(selectedStudentActiveSchedule)
                                .length > 0 &&
                              this.selectedStudentActiveSchedule.user
                                ?.id_user !==
                                this.selectedStudentGuideTeacher.user?.id_user
                            "
                          >
                            <v-list-item-avatar color="primary lighten-5">
                              <v-icon color="primary" dark>
                                mdi-human-male-board
                              </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                >Notificar docente actual</v-list-item-title
                              >
                            </v-list-item-content>
                            <v-list-item-icon>
                              <v-checkbox
                                class="mb-n5"
                                v-model="notifyCurrentTeacher"
                              ></v-checkbox>
                            </v-list-item-icon>
                          </v-list-item>
                          <!-- ------------------------------ -->
                        </v-list-item-group>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-divider></v-divider>
            </v-timeline>
            <v-row justify="center">
              <v-col cols="5">
                <v-btn
                  block
                  outlined
                  @click="returnTo('newVisit')"
                  class="mt-3"
                  color="primary"
                >
                  Nueva visita
                </v-btn>
              </v-col>
              <v-col cols="5">
                <v-btn
                  :loading="
                    notifyingGuideTeacher ||
                    notifyingCurrentTeacher ||
                    isSavingClinicVisit
                  "
                  :disabled="
                    notifyingGuideTeacher ||
                    notifyingCurrentTeacher ||
                    isSavingClinicVisit
                  "
                  block
                  class="mt-3"
                  @click="saveClicnicVisit()"
                  color="primary"
                >
                  Registrar visita
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <!-- --------------------------------------------------------------- -->

          <v-col
            v-if="
              isLoadingIndividualStudentInformation ||
              isLoadingStudentGuideTeacher ||
              isLoadingActiveSchedule
            "
            cols="12"
            md="4"
            class="d-flex justify-center align-center flex-column py-15"
          >
            <v-card
              class="pa-5 rounded-lg d-flex justify-center align-center flex-column py-15"
            >
              <v-progress-circular
                :size="70"
                :width="7"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <p class="mt-5">Obteniendo datos de estudiante...</p>
            </v-card>
          </v-col>

          <v-col v-else cols="12" md="4">
            <v-card class="pa-5 rounded-lg">
              <v-row class="d-flex justify-center">
                <v-col cols="4">
                  <v-img
                    :src="selectedStudent.academicLevelDetails?.photo"
                    :alt="selectedStudent.academicLevelDetails?.full_name"
                    :lazy-src="selectedStudent.academicLevelDetails?.photo"
                    aspect-ratio="0.80"
                    class="grey lighten-2 rounded-lg"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <p
                    class="text-h5 font-weight-medium blue--text text--darken-2 text-center mb-1"
                  >
                    {{ selectedStudent.academicLevelDetails?.code }}
                  </p>
                  <p class="text-body-1 font-weight-bold text-center mb-0">
                    {{ selectedStudent.academicLevelDetails?.first_name }}
                  </p>
                  <p class="text-body-1 font-weight-bold text-center mb-0">
                    {{ selectedStudent.academicLevelDetails?.last_name }}
                  </p>
                  <p
                    class="text-body-1 font-weight-bold text-center mb-0 grey--text text--darken-2"
                  >
                    {{ selectedStudent.academicLevelDetails?.grade }}
                    {{ selectedStudent.academicLevelDetails?.speciality }}
                  </p>
                  <p class="text-secundary text-center">
                    {{ selectedStudent.academicLevelDetails?.email }}
                  </p>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-card
                outlined
                class="rounded-lg pa-5 mb-3"
                v-for="(familiar, index) in selectedStudent.managers"
                :key="index"
              >
                <v-row>
                  <v-chip
                    class="ma-2"
                    color="indigo lighten-5"
                    text-color="white"
                  >
                    <v-avatar left>
                      <v-icon color="indigo"
                        >mdi-{{
                          familiar.isManager
                            ? "shield-account-outline"
                            : "account-circle-outline"
                        }}</v-icon
                      >
                    </v-avatar>
                    <span class="indigo--text font-weight-bold">
                      {{
                        familiar.isManager
                          ? "Responsable ante institución"
                          : "Familiar"
                      }}</span
                    >
                  </v-chip>
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="12">
                    <p class="mb-0 font-weight-bold">
                      {{ familiar.firstName }} {{ familiar.lastName }}
                    </p>
                    <p class="mb-0 font-weight-medium">
                      Correo: {{ familiar.email }}
                    </p>
                    <p class="mb-0">Número: {{ familiar.cellphone }}</p>
                  </v-col>
                </v-row>
              </v-card>

              <v-divider></v-divider>

              <v-row v-if="selectedStudent.prescribedMedicines.length > 0">
                <v-col cols="12">
                  <p class="text-h6 font-weight-bold mb-0">
                    Medicamentos preescritos
                  </p>
                </v-col>
                <v-col class="py-0">
                  <v-chip
                    v-for="(
                      prescribedMedicine, index
                    ) in selectedStudent.prescribedMedicines"
                    :key="index"
                    class="mr-2 mb-2"
                    color="indigo lighten-5 "
                    text-color="white"
                  >
                    <v-icon left color="indigo">mdi-hospital</v-icon>

                    <span class="indigo--text font-weight-bold">{{
                      prescribedMedicine.Medicine?.name
                    }}</span>
                  </v-chip>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  <v-card class="pa-5" outlined>
                    <p class="text-center font-weight-medium mb-0">
                      No hay medicamentos preescritos registrados.
                    </p>
                  </v-card>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row v-if="selectedStudent.allergicMedicines.length > 0">
                <v-col cols="12">
                  <p class="text-h6 font-weight-bold mb-0">Alérgico a</p>
                </v-col>
                <v-col class="py-0">
                  <v-chip
                    v-for="(
                      prescribedMedicine, index
                    ) in selectedStudent.allergicMedicines"
                    :key="index"
                    class="mr-2 mb-2"
                    color="indigo lighten-5 "
                    text-color="white"
                  >
                    <v-icon left color="indigo"
                      >mdi-alert-octagram-outline</v-icon
                    >

                    <span class="indigo--text font-weight-bold">{{
                      prescribedMedicine.Medicine?.name
                    }}</span>
                  </v-chip>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  <v-card class="pa-5" outlined>
                    <p class="text-center font-weight-medium mb-0">
                      No hay alergias a medicamentos registradas.
                    </p>
                  </v-card>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row v-if="selectedStudent.diseases.length > 0">
                <v-col cols="12">
                  <p class="text-h6 font-weight-bold mb-0">
                    Padecimientos registrados
                  </p>
                </v-col>
                <v-col class="py-0">
                  <v-chip
                    v-for="(studentDisease, index) in selectedStudent.diseases"
                    :key="index"
                    class=""
                    color="indigo lighten-5 mr-2 mb-2"
                    text-color="white"
                  >
                    <v-icon left color="indigo"
                      >mdi-alert-octagon-outline</v-icon
                    >

                    <span class="indigo--text font-weight-bold">{{
                      studentDisease.Disease?.name
                    }}</span>
                  </v-chip>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  <v-card class="pa-5" outlined>
                    <p class="text-center font-weight-medium mb-0">
                      No hay padecimientos registrados.
                    </p>
                  </v-card>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row
                v-if="!Object.keys(selectedStudentGuideTeacher).length > 0"
              >
                <v-col cols="12">
                  <v-card class="pa-5" outlined>
                    <p class="text-center font-weight-medium mb-0">
                      No tiene docente guía.
                    </p>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  <p class="text-h6 font-weight-bold mb-0">Docente guía</p>
                </v-col>
                <v-col class="py-0">
                  <p class="mb-0 font-weight-bold">
                    {{ selectedStudentGuideTeacher?.user.first_name }}
                    {{ selectedStudentGuideTeacher?.user.last_name }}
                  </p>
                  <p class="mb-4 font-weight-medium">
                    {{ selectedStudentGuideTeacher?.user.email }}
                  </p>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row
                v-if="!Object.keys(selectedStudentActiveSchedule).length > 0"
              >
                <v-col cols="12">
                  <v-card class="pa-5" outlined>
                    <p class="text-center font-weight-medium mb-0">
                      No tiene clases por el momento.
                    </p>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  <p class="text-h6 font-weight-bold mb-0">
                    Docente clase actual
                  </p>
                </v-col>
                <v-col class="py-0">
                  <v-chip
                    class="mb-1"
                    color="indigo lighten-5"
                    text-color="white"
                  >
                    <v-avatar left>
                      <v-icon color="indigo">mdi-clock-outline</v-icon>
                    </v-avatar>
                    <span class="indigo--text font-weight-bold">{{
                      selectedStudentActiveSchedule?.block
                    }}</span>
                  </v-chip>
                  <p class="mb-0 font-weight-bold">
                    {{ selectedStudentActiveSchedule?.user.first_name }}
                    {{ selectedStudentActiveSchedule?.user.last_name }}
                  </p>
                  <p class="mb-0 font-weight-medium">
                    {{ selectedStudentActiveSchedule?.subject }}
                  </p>
                  <p class="mb-0 font-weight-medium grey--text text--darken-1">
                    {{ selectedStudentActiveSchedule?.local }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <!-- Begin:: Set of steps to register a successful clicnical visit -->

        <!-- End:: Set of steps to register a successful clicnical visit -->

        <div v-else>
          <div v-if="isStudent" class="d-flex flex-column py-10 align-center">
            <v-avatar color="green lighten-4" size="96" class="mt-10 mb-5">
              <v-icon large color="green"> mdi-account-search-outline </v-icon>
            </v-avatar>

            <p class="text-body-1 text-center">
              Puede encontrar un estudiante por medio de su nombre, apellido y/o
              código.
            </p>
          </div>
        </div>
      </div>
    </div>
    <NewClinicVisitConfirmationModal
      ref="mdlNewVisitConfirmation"
      :buttonClicked="handleReturn"
      @action="handleClickReturn"
    ></NewClinicVisitConfirmationModal>
    <RegisterGivenDosisModal
      ref="mdlRegisterGivenDosis"
      @action="saveGivenDosisRecord($event)"
      @clear-selection="clearSelectedMedicine"
      :givenMedicine="selectedMedicine"
    >
    </RegisterGivenDosisModal>
    <RegisterVitalSignModal
      ref="mdlRegisterVitalSign"
      @action="saveVitalSignRecord($event)"
      @clear-selection="clearSelectedVitalSign"
      :recordedVitalSign="selectedVitalSign"
    >
    </RegisterVitalSignModal>
    <ManageMedicineModal
      ref="mdlMedicine"
      :medicinesInfo="medicines"
      @reload="getMedicines"
    ></ManageMedicineModal>
    <ManageDiseaseModal
      ref="mdlDisease"
      :diseasesInfo="diseases"
      @reload="getDiseases"
    ></ManageDiseaseModal>
    <ManageVitalSignsModal
      ref="mdlVistalSigns"
      :vitalSignsInfo="vitalSigns"
      @reload="getVitalSigns"
    >
    </ManageVitalSignsModal>
  </div>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
//
import ManageDiseaseModal from "@/components/modals/clinical_nursing/ManageDiseaseModal.vue";
import ManageMedicineModal from "@/components/modals/clinical_nursing/ManageMedicineModal.vue";
import ManageVitalSignsModal from "@/components/modals/clinical_nursing/ManageVitalSignsModal.vue";
import studentRepository from "@/repositories/studentRepository";
import nursingRepository from "@/repositories/nursingRepository";
import schedulesRepository from "@/repositories/schedulesRepository";

import { required } from "vuelidate/lib/validators";

import RegisterGivenDosisModal from "@/components/modals/medical_visit/RegisterGivenDosisModal";
import RegisterVitalSignModal from "@/components/modals/medical_visit/RegisterVitalSignModal";

import NewClinicVisitConfirmationModal from "@/components/modals/clinical_nursing/NewClinicVisitConfirmationModal.vue";
import emailSenderRepository from "@/repositories/emailSenderRepository";

export default {
  name: "NewClinicalVisitForm",
  title: "Unidad de primeros auxilios | GE ITR",

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Unidad de primeros auxilios", route: "/clinical_nursing" },
      {
        title: "Visita de estudiante",
        route: "new_student_clinical_visit?student=true",
      },
    ]);
    this.getMedicines();
    this.getDiseases();
    this.getVitalSigns();
  },

  components: {
    RegisterGivenDosisModal,
    RegisterVitalSignModal,
    ManageDiseaseModal,
    ManageMedicineModal,
    ManageVitalSignsModal,
    NewClinicVisitConfirmationModal,
  },

  props: {
    //subject id
    student: {
      type: String,
    },
  },

  data() {
    return {
      // Adjust the delay as needed in milliseconds
      searchDelay: 600,
      searchTimer: null,

      notifyingCurrentTeacher: false,
      notifyingGuideTeacher: false,
      isSavingClinicVisit: false,
      isLoadingStudentGuideTeacher: false,
      isLoadingActiveSchedule: false,
      notifyCurrentTeacher: true,
      notifyGuideTeacher: true,
      selectedItem: [],

      students: {
        search: "",
        isLoading: false,
        data: [],
        headers: [
          {
            text: "Estudiante",
            align: "start",
            value: "full_name",
          },
        ],
      },

      selectedStudent: {},
      selectedMedicine: {},
      selectedIllness: {},
      selectedVitalSign: {},

      medicines: {
        data: [],
        isLoading: false,
      },
      diseases: {
        data: [],
        isLoading: false,
      },
      vitalSigns: {
        data: [],
        isLoading: false,
      },

      selectedStudentActiveSchedule: {},

      selectedStudentGuideTeacher: {},

      onlyOneStudent: true,

      clinicVisit: {
        patient_id: null,
        patient_type: "Student", //Puede ser "ExternalPatient" ó "Student"
        diagnostic: "",
        user_id: null,
        medication_detail: [],
        disease_id: [],
        vital_sign_id: [],
      },

      handleReturn: null,
    };
  },

  watch: {
    "students.search"(newVal) {
      clearTimeout(this.searchTimer); // Clear the previous timer

      // Set a new timer to trigger the search after the delay
      this.searchTimer = setTimeout(() => {
        this.searchStudentsOnKeyPress();
      }, this.searchDelay);
    },
  },

  methods: {
    getVitalSigns() {
      this.vitalSigns.isLoading = true;
      nursingRepository
        .getAllVitalSigns()
        .then(({ data }) => {
          this.vitalSigns.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los signos vitales",
          });
        })
        .finally(() => {
          this.vitalSigns.isLoading = false;
        });
    },
    async getMedicines() {
      try {
        this.medicines.isLoading = true;
        const res = await axios.get(`${this.getFormApiURL}/medicines`);
        this.medicines.data = res.data;
        this.medicines.isLoading = false;
      } catch (error) {
        console.log("ERROR: ", error);
        this.medicines.isLoading = false;
        this.fireToast({
          icon: "error",
          title: "No ha sido posible cargar los medicamentos",
        });
      }
    },
    async getDiseases() {
      try {
        this.diseases.isLoading = true;
        const res = await axios.get(`${this.getFormApiURL}/diseases`);
        this.diseases.data = res.data;
        this.diseases.isLoading = false;
      } catch (error) {
        this.diseases.isLoading = false;
        this.fireToast({
          icon: "error",
          title: "No ha sido posible cargar los medicamentos",
        });
      }
    },

    handleClickReturn(condition) {
      if (condition === "dashboard") {
        this.returnToDashboard();
        this.resetForm();
      } else if (condition === "newVisit") {
        this.resetForm();
      }
    },

    returnTo(condition) {
      if (condition === "dashboard") {
        this.handleReturn = "dashboard";
      } else if (condition === "newVisit") {
        this.handleReturn = "newVisit";
      }
      this.$refs.mdlNewVisitConfirmation.toggleModal();
    },
    /****fetching student information****/

    // 1. First, fetching all students
    async searchStudentsOnKeyPress() {
      if (this.students.search === "" || this.students.search === null) {
        this.students.data = []; // Clear the items if the search input is empty
        return;
      }

      this.students.isLoading = true;

      studentRepository
        .getStudentsOnTypedMatch(`${this.students.search}`)
        .then((data) => {
          this.students.data = data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          this.students.isLoading = false;
        });
    },

    //2. Second, Fetching the all the individual information from the selected student
    getStudentInformationUpdated(item) {
      this.loadingDialog = true;
      this.isLoadingIndividualStudentInformation = true;
      this.students.isLoading = true;

      let fetchStudentInfo = studentRepository.getStudentInformationByUUID(
        item.uuid
      );

      let fetchStudentDetails = studentRepository.getStudentInformation({
        headers: {
          Authorization: `${item.uuid}`,
        },
      });

      Promise.all([fetchStudentInfo, fetchStudentDetails])
        .then((responses) => {
          let academicLevelDetails = responses[0].data;
          let fullStudentDetails = responses[1].data;

          //"fullStudentDetails" contanins detailed student information
          //"academicLevelDetails" contains general academic details
          //Both pieces of information come from diferent API's
          this.selectStudent(fullStudentDetails, academicLevelDetails);
        })
        .catch((err) => {
          console.warn(
            "Error al obtener información general de estudiante",
            err
          );
        })
        .finally(() => {
          this.students.isLoading = false;
          this.isLoadingIndividualStudentInformation = false;
        });
    },

    //2.1
    async selectStudent(obj, academicLevelDetails) {
      let uuid = obj.uuid;
      //later on we'll need to add some type of auth
      let headers = {
        Authorization: `${uuid}`,
      };
      // invoking complementing functions
      let studentDiseases = this.fetchData(
        "student-diseases/diseases",
        headers
      );

      let studentPrescribedMedicines = this.fetchData(
        "student-medicines/medicine/prescribed",
        headers
      );
      let studentAllergicMedicines = this.fetchData(
        "student-medicines/medicine/allergic",
        headers
      );
      let studentManagers = this.fetchData("student-managers", headers);
      let studentFamilyDetails = this.fetchData(
        "student-familiar-informations",
        headers
      );

      Promise.all([
        studentDiseases,
        studentPrescribedMedicines,
        studentAllergicMedicines,
        studentManagers,
        studentFamilyDetails,
      ])
        .then((data) => {
          this.selectedStudent = {
            diseases: data[0],
            prescribedMedicines: data[1],
            allergicMedicines: data[2],
            managers: data[3],
            familyDetails: data[4],
            details: { ...obj },
            academicLevelDetails: { ...academicLevelDetails },
          };

          this.clinicVisit.patient_id =
            this.selectedStudent.academicLevelDetails.uuid;
          this.clinicVisit.user_id = this.currentUserPersonalInfo.id_user;
          this.getStudentGuideTeacher();
          this.getStudentCurrentSchedule();
        })
        .catch((error) => {
          console.warn(
            "Hubo un error al obtener los datos del estudiante: ",
            error
          );
        })
        .finally(() => {
          this.loadingAllStudentData = false;
        });
    },

    showMdlDiseases() {
      this.$refs.mdlDisease.toggleModal();
    },

    showMdlMedicines() {
      this.$refs.mdlMedicine.toggleModal();
    },

    showMdlVitalSigns() {
      this.$refs.mdlVistalSigns.toggleModal();
    },

    //Flow managing
    /*If there's a code available when an "onChange" event takes
      place, the request will be made. otherwise, dont.*/
    get(student) {
      if (student != null) {
        //request functions
        this.getStudentInformationUpdated(student.uuid);
        return;
      }
    },

    getExternalPersonDetails(person) {
      if (person != null) {
        //request functions
        alert("Show form because user already exits");
        return;
      }
    },

    async fetchData(endpoint, headers) {
      let baseURL;

      if (process.env.ADMISION_APP_ENV === "production") {
        baseURL = process.env.ADMISION_API_URL;
      } else if (process.env.ADMISION_APP_ENV === "development") {
        baseURL = process.env.ADMISION_DEV_API_URL;
      } else {
        baseURL = process.env.ADMISION_LOCAL_API_URL;
      }

      let url = `${baseURL}/${endpoint}`;

      const response = await fetch(url, { headers });
      return response.json();
    },

    async fetchDataInStudentsApi(endpoint, headers) {
      let baseURL;

      if (process.env.APP_ENV === "production") {
        baseURL = process.env.GE_STUDENTS_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.GE_STUDENTS_DEV_API_URL;
      } else {
        baseURL = process.env.GE_STUDENTS_LOCAL_API_URL;
      }

      let url = `${baseURL}/${endpoint}`;
      const response = await fetch(url, { headers });
      return response.json();
    },

    wipeCardDetails() {
      this.allValidInputs = false;
      this.selectedStudent = {};
      this.selectedStudentStages.selectedStage = {};
      this.disciplineStats.data = {};

      this.studentDisciplineTimestampMetadata.studentUUID = null;
      this.studentDisciplineTimestampMetadata.startDate = null;
      this.studentDisciplineTimestampMetadata.endDate = null;
    },

    customFilter(item, queryText) {
      const searchText = queryText
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const codeMatch = item.code.toString().includes(searchText);
      const firstName = item.first_name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(searchText);
      const lastName = item.last_name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(searchText);
      return codeMatch || firstName || lastName || firstName + lastName;
    },

    customFilterExternalPeople(item, queryText) {
      const fullName = item.full_name.toLowerCase();
      const query = queryText.toLowerCase();

      return fullName.includes(query);
    },

    handlePaste(event) {
      event.preventDefault();
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      paste = paste.replace(/ {3,}/g, "  ");
      this.clinicVisit.diagnostic = this.clinicVisit.diagnostic + paste;
    },

    //------------------------

    openDosisRegistrationModal() {
      this.$refs.mdlRegisterGivenDosis.toggleModal();
    },

    clearSelectedMedicine() {
      this.selectedMedicine = {};
    },

    removeGivenMedicineFormList(selectedGivenMedicineID) {
      const indexToRemove = this.clinicVisit.medication_detail.findIndex(
        (item) => item.medicine_id === selectedGivenMedicineID
      );
      if (indexToRemove !== -1) {
        this.clinicVisit.medication_detail.splice(indexToRemove, 1);
      }
    },

    saveGivenDosisRecord(givenMedicineWithDosis) {
      this.clinicVisit.medication_detail.push(givenMedicineWithDosis);
    },

    //------------------------

    openVitalSignsRegistrationModal() {
      this.$refs.mdlRegisterVitalSign.toggleModal();
    },

    clearSelectedVitalSign() {
      this.selectedVitalSign = {};
    },

    removeVitalSignFormList(selectedVitalSignRecordID) {
      const indexToRemove = this.clinicVisit.vital_sign_id.findIndex(
        (item) => item.id === selectedVitalSignRecordID
      );
      if (indexToRemove !== -1) {
        this.clinicVisit.vital_sign_id.splice(indexToRemove, 1);
      }
    },

    saveVitalSignRecord(vitalSignRecord) {
      this.clinicVisit.vital_sign_id.push(vitalSignRecord);
    },

    //-------------------DISEASES----------------------
    resetSelectedIllnes() {
      this.selectedIllness = {};
      this.clinicVisit.disease_id = [];
    },
    saveDiseasesRecord(selectedIllnessFormDiagnostic) {
      if (selectedIllnessFormDiagnostic === null) {
        return;
      }
      this.clinicVisit.disease_id = [];
      this.clinicVisit.disease_id.push({
        id: selectedIllnessFormDiagnostic.id,
      });
    },

    //---------------------
    getStudentCurrentSchedule() {
      this.isLoadingActiveSchedule = true;
      let studentUUID = {
        student_uuid: this.selectedStudent.academicLevelDetails.uuid,
      };
      schedulesRepository
        .getStudentActiveSchedule(studentUUID)
        .then(({ data }) => {
          this.selectedStudentActiveSchedule = data.data;
        })
        .catch((error) => {
          console.warn(
            "Error al traer el horario activo del estudiante: ",
            error
          );
        })
        .finally(() => {
          this.isLoadingActiveSchedule = false;
        });
    },

    getStudentGuideTeacher() {
      this.isLoadingStudentGuideTeacher = true;
      let groupID = 0;
      let academicLevel =
        this.selectedStudent.academicLevelDetails.grade_academic_level_id;
      // 1 = Tercer Ciclo, 2 = Bachillerato
      if (academicLevel == 2) {
        groupID =
          this.selectedStudent.academicLevelDetails.grade_speciality_group_id;
      } else {
        groupID =
          this.selectedStudent.academicLevelDetails.grade_section_group_id;
      }

      nursingRepository
        .getStudentGuideTeacherByGradeSectionOrSpecialityGroupID(
          groupID,
          academicLevel
        )
        .then(({ data }) => {
          this.selectedStudentGuideTeacher = data;
        })
        .catch((error) => {
          console.warn(
            "Error al traer el horario activo del estudiante: ",
            error
          );
        })
        .finally(() => {
          this.isLoadingStudentGuideTeacher = false;
        });
    },

    saveClicnicVisit() {
      this.$v.clinicVisit.$touch();
      if (!this.$v.clinicVisit.$invalid) {
        this.isSavingClinicVisit = true;
        nursingRepository
          .createClinicVisit(this.clinicVisit)
          .then((res) => {
            this.sendAlertEmails();
            this.sweetAlertResponse({
              status: res.data?.status,
              message: `${res.data?.message}`,
            });
            this.resetForm();
            this.$router.push({
              path: "/clinical_nursing",
            });
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: `No ha sido posible subir los datos a el servidor`,
            });
          })
          .finally(() => {
            this.isSavingClinicVisit = false;
          });
        return;
      }
      this.sweetAlertResponse({
        status: false,
        message: "Debe adjuntar un detalle de visita del estudiante.",
      });
    },

    sendAlertEmails() {
      let notifiedGuideTeacher = false;
      let notifiedCurrentTeacher = false;

      if (
        Object.entries(this.selectedStudentActiveSchedule).length > 0 &&
        this.notifyCurrentTeacher &&
        this.selectedStudentActiveSchedule.user.id_user !==
          this.selectedStudentGuideTeacher.user.id_user
      ) {
        this.notifyingCurrentTeacher = true;

        const composedEmail = {
          recipients: `${this.selectedStudentActiveSchedule.user.email}`,
          subject: "Aviso de visita",
          htmlBody: `
          <!DOCTYPE html>
          <html lang="en">

          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Document</title>
            <style>
              .container {
                display: table;
                width: 100%;
                /* Make the table fill the container's width */
              }

              .container-cell {
                display: table-cell;
                vertical-align: middle;
                text-align: center;
              }

              .container img {
                max-width: 100%;
                /* Ensure the image doesn't exceed the container's width */
                height: auto;
                /* Maintain the image's aspect ratio */
                margin: 0 auto;
                /* Center the image horizontally */
              }

              footer {
                text-align: center;
                padding: 30px 0px;
                color: #fff;
                background-color: #0b84f9;
              }
            </style>
          </head>

          <body style="background:#dbecfe; padding-top: 25px;">

            <div class="container">
              <div class="container-cell">

                <img src="cid:LogoRicaldone" alt="Logo Ricaldone">

                <h1 style="color:#0b3c6a;">Gestión Estudiantil ITR</h1>
                <h3 style="color:#0b3c6a;">Aviso de asistencia a unidad de primeros auxilios</h3>

              </div>
            </div>

            <p style="margin:0px 25px; margin-top:40px; color:#0b3c6a; line-height: 1.5; font-size: 1.1em;">Saludos, ${
              this.selectedStudentActiveSchedule.user.first_name
            } ${this.selectedStudentActiveSchedule.user.last_name}</p>

            <p style="margin:25px; color:#0b3c6a; text-align: justify; line-height: 1.5; font-size: 1.1em;">El día <span
                style="font-weight: bold;">${this.fullFormattedDate(
                  new Date(
                    Date.now() - new Date().getTimezoneOffset() * 60000
                  ).toISOString()
                )} </span> a las <span
                style="font-weight: bold;">${dayjs().format(
                  "h:mm:ss A"
                )}</span> mientras se imparte <span style="font-weight: bold;">${
            this.selectedStudentActiveSchedule.subject
          }</span> <span style="font-weight: bold;">(${
            this.selectedStudentActiveSchedule.block
          })</span>, en el local <span style="font-weight: bold;">${
            this.selectedStudentActiveSchedule.local
          }</span>;

              ${
                this.clinicVisit.disease_id.length > 0
                  ? `el estudiante <span style="font-weight: bold;">${this.selectedStudent.academicLevelDetails.code} - ${this.selectedStudent.academicLevelDetails.full_name}</span> se
              presentó a la unidad de primeros auxilios con el siguiente detalle: <br><br> <span style="font-style: italic;">${this.clinicVisit.diagnostic}</span>`
                  : ` el estudiante <span style="font-weight: bold;">${this.selectedStudent.academicLevelDetails.code} - ${this.selectedStudent.academicLevelDetails.full_name}</span>
              se presentó a la unidad de primeros auxilios con el siguiente detalle: <br><br> <span
                style="font-style: italic;">${this.clinicVisit.diagnostic}</span>`
              }

            </p>

            <footer>
              <h5 style="color: #fff">Has recibido este correo automáticamente para notificarte desde el sistema de Gestión
                Estudiantil ITR</h5>
            </footer>

          </body>

          </html>
            `,
          plainTextEmailPurpouseString:
            "Aviso de asistencia a unidad de primeros auxilios",
          attachments: [
            {
              filename: "logo.png",
              path: "app/resources/img/logo.png",
              cid: "LogoRicaldone",
            },
          ],
        };

        emailSenderRepository
          .composeNewEmail(composedEmail)
          .then((res) => {
            notifiedCurrentTeacher = true;
          })
          .catch((error) => {
            console.warn(error);
          })
          .finally(() => {
            this.notifyingCurrentTeacher = false;
          });
      }

      //notify guide teacher

      if (
        Object.entries(this.selectedStudentGuideTeacher).length > 0 &&
        this.notifyGuideTeacher
      ) {
        this.notifyingGuideTeacher = true;

        const composedEmail = {
          recipients: `${this.selectedStudentGuideTeacher.user.email}`,
          subject: "Aviso de visita",
          htmlBody: `
          <!DOCTYPE html>
          <html lang="en">

          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Document</title>
            <style>
              .container {
                display: table;
                width: 100%;
                /* Make the table fill the container's width */
              }

              .container-cell {
                display: table-cell;
                vertical-align: middle;
                text-align: center;
              }

              .container img {
                max-width: 100%;
                /* Ensure the image doesn't exceed the container's width */
                height: auto;
                /* Maintain the image's aspect ratio */
                margin: 0 auto;
                /* Center the image horizontally */
              }

              footer {
                text-align: center;
                padding: 30px 0px;
                color: #fff;
                background-color: #0b84f9;
              }
            </style>
          </head>

          <body style="background:#dbecfe; padding-top: 25px;">

            <div class="container">
              <div class="container-cell">

                <img src="cid:LogoRicaldone" alt="Logo">

                <h1 style="color:#0b3c6a;">Gestión Estudiantil ITR</h1>
                <h3 style="color:#0b3c6a;">Aviso de asistencia a unidad de primeros auxilios</h3>

              </div>
            </div>

            <p style="margin:0px 25px; margin-top:40px; color:#0b3c6a; line-height: 1.5; font-size: 1.1em;">Saludos, ${
              this.selectedStudentGuideTeacher.user.first_name
            } ${this.selectedStudentGuideTeacher.user.last_name}</p>

            <p style="margin:25px; color:#0b3c6a; text-align: justify; line-height: 1.5; font-size: 1.1em;">El día <span
                style="font-weight: bold;">${this.fullFormattedDate(
                  new Date(
                    Date.now() - new Date().getTimezoneOffset() * 60000
                  ).toISOString()
                )} </span>a las <span
                style="font-weight: bold;">${dayjs().format(
                  "h:mm:ss A"
                )}</span> ${
            Object.entries(this.selectedStudentActiveSchedule).length > 0
              ? ` durante la hora se imparte <span style="font-weight: bold;">${this.selectedStudentActiveSchedule.subject}</span> <span style="font-weight: bold;">(${this.selectedStudentActiveSchedule.block})</span>, en el local <span style="font-weight: bold;">${this.selectedStudentActiveSchedule.local}</span>;`
              : ","
          }

              ${
                this.clinicVisit.disease_id.length > 0
                  ? `el estudiante <span style="font-weight: bold;">${this.selectedStudent.academicLevelDetails.code} - ${this.selectedStudent.academicLevelDetails.full_name}</span> del cual usted es docente guía, se
              presentó a la unidad de primeros auxilios con el siguiente detalle: <br><br> <span style="font-style: italic;">${this.clinicVisit.diagnostic}</span>`
                  : ` el estudiante <span style="font-weight: bold;">${this.selectedStudent.academicLevelDetails.code} - ${this.selectedStudent.academicLevelDetails.full_name}</span> del cual usted es docente guía, se presentó a la unidad de primeros auxilios con el siguiente detalle: <br><br><span
                style="font-style: italic;">${this.clinicVisit.diagnostic}</span>`
              }

            </p>

            <footer>
              <h5 style="color: #fff">Has recibido este correo automáticamente para notificarte desde el sistema de Gestión
                Estudiantil ITR</h5>
            </footer>

          </body>

          </html>
            `,
          plainTextEmailPurpouseString:
            "Aviso de asistencia a unidad de primeros auxilios",
          attachments: [
            {
              filename: "logo.png",
              path: "app/resources/img/logo.png",
              cid: "LogoRicaldone",
            },
          ],
        };

        emailSenderRepository
          .composeNewEmail(composedEmail)
          .then((res) => {
            notifiedGuideTeacher = true;
          })
          .catch((error) => {
            console.warn(error);
          })
          .finally(() => {
            this.notifyingGuideTeacher = false;
          });
      }

      //emitting alert

      if (notifiedGuideTeacher && notifiedCurrentTeacher) {
        this.fireToast({
          icon: "success",
          title: `Se notificó al docente guía y al docente actual`,
        });
      }

      if (notifiedCurrentTeacher) {
        this.fireToast({
          icon: "success",
          title: `Se notificó al docente actual`,
        });
      }

      if (notifiedGuideTeacher) {
        this.fireToast({
          icon: "success",
          title: `Se notificó al docente guía`,
        });
      }
    },

    resetForm() {
      this.selectedStudent = {};
      this.selectedMedicine = {};
      this.selectedIllness = {};
      this.selectedVitalSign = {};
      this.clinicVisit = {
        patient_id: null,
        patient_type: "Student", //Puede ser "ExternalPatient" ó "Student"
        visit_date_time: null,
        diagnostic: "",
        user_id: null,
        medication_detail: [],
        disease_id: [],
        vital_sign_id: [],
      };
      this.clearSelectedMedicine();
      this.clearSelectedVitalSign();
      this.$v.$reset();
    },

    returnToDashboard() {
      this.$router.push({
        path: "/clinical_nursing",
      });
    },
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    formattedStudents() {
      return this.students.data.map((item) => ({
        uuid: item.uuid,
        photo: item.photo,
        full_name: `${item.first_name} ${item.last_name}`,
        code: item.code,
        loading: false,
      }));
    },
    isStudent() {
      return /true/i.test(this.student);
    },
    hasSelectedStudent() {
      if (this.selectedStudent !== undefined && this.selectedStudent !== null) {
        return Object.entries(this.selectedStudent).length !== 0;
      }
      return false;
    },
    DP_URL() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.DP_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.DP_DEV_API_URL;
      } else {
        baseURL = process.env.DP_LOCAL_API_URL;
      }
      return baseURL;
    },
    getFormApiURL() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.ADMISION_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.ADMISION_DEV_API_URL;
      } else {
        baseURL = process.env.ADMISION_LOCAL_API_URL;
      }
      return baseURL;
    },
  },

  validations: {
    clinicVisit: {
      diagnostic: {
        required,
      },
    },
  },
};
</script>

<style>
.cursor {
  cursor: pointer;
}
.disable-pointer-events {
  pointer-events: none;
}
</style>
